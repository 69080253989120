<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 120px">
                                物料清单名称：
                            </td>
                            <td>
                                <el-input v-model="searchItem.contractNum" size="small" placeholder="请输入物料清单名称"
                                ></el-input>
                            </td>
                            <td style="width: 80px">
                                适用机型：
                            </td>
                            <td>
                                <el-select v-model="searchItem.warehouseType" style="width: 100%" size="small"
                                           placeholder="请选择适用机型">
                                    <el-option
                                            v-for="item in deviceTypes"
                                            :key="item.wholeCode"
                                            :label="item.name"
                                            :value="item.wholeCode">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 300px ;text-align: center">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-circle-plus" size="small" @click="addObject">
                                    增加
                                </el-button>
                            </td>
                            <td style="width: 100px;">
                            </td>
                            <td style="width: 26%">
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                            <el-table-column prop="listName" width="400" label="物料清单名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column width="150" label="适用机型" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.deviceType == 0">塔机</span>
                                    <span v-else-if="scope.row.deviceType == 1">升降机</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="remark" label="备注" align="left"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="number" width="150" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="operation">
                                        <!-- v-if="hasAuthority('out_depot_add')"-->
                                        <i class="iconfont iconbianji" title="编辑"
                                           @click="editPacking(scope.row.id)"></i>
                                        <i class="iconfont iconxiangqing" title="编辑"
                                           @click="showPacking(scope.row.id)"></i>
                                        <i class="iconfont iconshanchu" title="编辑"
                                           @click="deletePacking(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog-form">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoDialogVisible"
                    :close-on-click-modal="false"
                    style="margin-top: -90px"
                    width="50%"
                    center>
                <div style="max-height: 650px;overflow-y: auto">
                    <table style="width: 98%;">
                        <tr>
                            <td style="text-align: left;width: 120px">
                                <span>物料清单名称：</span>
                            </td>
                            <td style="text-align: left" width="40%">
                                <span>{{packDetail.listName}}</span>
                            </td>
                            <td style="text-align: left;width: 80px">
                                <span>适用机型：</span>
                            </td>
                            <td style="text-align: left" width="40%">
                                <span>{{packDetail.deviceType == 0?'塔机':'升降机'}}</span>
                            </td>
                        </tr>
                    </table>
                    <table style="width: 98%;" v-for="(value, key) in table" :key="key">
                        <tr>
                            <td style="text-align: left;width: 120px">
                                <div class="text-show">{{key}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" style="padding-bottom: 20px;padding-top: 10px">
                                <el-table
                                        :data="value"
                                        border
                                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                        :cell-style="{padding:'0',height: lineHeight}"
                                >
                                    <el-table-column prop="materialCode" label="物料编码" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="materialName" label="产品名称" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="count" label="数量" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="model" label="产品型号" align="center"
                                                     show-overflow-tooltip></el-table-column>
                                </el-table>
                            </td>
                        </tr>
                    </table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoDialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="dialog-form edit">
            <el-dialog
                    :title="editTitle"
                    :visible.sync="editDialogVisible"
                    :close-on-click-modal="false"
                    width="50%"
                    style="margin-top: -90px"
                    center>
                <div ref="middle">
                    <el-form :model="packForm" :rules="packRules" ref="packForm" label-width="110px"
                             class="demo-ruleForm">
                        <el-row>
                            <el-col :span="10" style="width: 50%">
                                <el-form-item size="small" label="物料清单名称" prop="listName">
                                    <el-input size="small" v-model="packForm.listName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" style="width: 50%">
                                <el-form-item label="适用机型" prop="deviceType">
                                    <el-select v-model="packForm.deviceType" style="width: 100%" size="small"
                                               placeholder="请选择适用机型">
                                        <el-option
                                                v-for="item in deviceTypes"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item size="small" label="备注" prop="remark">
                            <el-input size="small" type="textarea" v-model="packForm.remark" row="3"></el-input>
                        </el-form-item>
                    </el-form>
                    <div class="add-tab">
                        <el-button type="primary" size="mini" icon="el-icon-plus" @click="addRow">新增部件</el-button>
                        <el-button type="primary" size="mini" icon="el-icon-plus" @click="addMaterial">新增物料</el-button>
                    </div>
                    <div style="max-height: 500px;overflow-y: auto">
                        <table style="width: 98%;" v-for="(value, key) in table" :key="key">
                            <tr>
                                <td style="text-align: left;width: 120px">
                                    <div class="text-show">{{key}}</div>
                                </td>
                                <td style="text-align: left">
                                    <el-button type="primary" size="mini" icon="el-icon-plus" style="margin-right: 12px"
                                               @click="addDetails(key,value)">新增物料
                                    </el-button>
                                    <el-button type="danger" size="mini" icon="el-icon-delete" style="margin-right: 12px"
                                               @click="deleteDetail(key)">删除此部件
                                    </el-button>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="padding-bottom: 20px;padding-top: 10px">
                                    <el-table
                                            :data="value"
                                            border
                                            :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                            :cell-style="{padding:'0',height: lineHeight}"
                                    >
                                        <el-table-column prop="materialCode" label="物料编码" align="center">
                                            <template slot-scope="scope">
                                                <el-select v-model="scope.row.materialCode" size="small" placeholder="请选择配置"
                                                           @change="chooseCode(scope.$index,value,scope.row.materialCode)"
                                                           filterable>
                                                    <el-option size="small" v-for="itemObj in materialList"
                                                               v-if="itemObj.componentName == key"
                                                               :key="itemObj.materialCode" :label="itemObj.materialCode"
                                                               :value="itemObj.materialCode"></el-option>
                                                </el-select>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="materialName" label="产品名称" align="center"></el-table-column>
                                        <el-table-column prop="count" label="数量" align="center">
                                            <template slot-scope="scope">
                                                <el-input type="tel" size="small" v-model.number="scope.row.count"
                                                          onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                                          placeholder="请输入数量"></el-input>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="model" label="产品型号" align="center">
                                            <template slot-scope="scope">
                                                <el-select v-model="scope.row.model" size="small" placeholder="请选择配置"
                                                           @change="chooseModel(scope.$index,value,scope.row.model)"
                                                           filterable>
                                                    <el-option size="small" v-for="itemObj in materialList"
                                                               v-if="itemObj.componentName == key"
                                                               :key="itemObj.id" :label="itemObj.model"
                                                               :value="itemObj.model"></el-option>
                                                </el-select>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="id" width="120" label="操作" align="center">
                                            <template slot-scope="scope">
                                                <i class="iconfont iconshanchu" style="color: red"
                                                   @click="deleteDetails(scope.$index,value)"></i>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel('packForm')">取 消</el-button>
                    <el-button type="primary" @click="submit('packForm')" :loading="isLoad">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="edit-dialog">
            <el-dialog title="新增部件及物料"
                       :visible.sync="dialogTableVisible"
                       :close-on-click-modal="false"
                       center>
                <table style="margin-bottom: 10px">
                    <tr>
                        <td style="width: 80px">
                            新增部件：
                        </td>
                        <td>
                            <el-select v-model="component" size="small"
                                       style="width: 100%"
                                       filterable
                                       @change="getComponent"
                                       placeholder="请选择部件">
                                <el-option
                                        v-for="item in componentList"
                                        :key="item.wholeCode"
                                        :label="item.name"
                                        :value="item.wholeCode">
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                </table>
                <el-table
                        ref="multipleTable"
                        :data="tableList"
                        tooltip-effect="dark"
                        style="width: 100%"
                        max-height="400"
                        border
                        :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                        :cell-style="{padding:'0',height: lineHeight}"
                        @selection-change="handleSelectionChange"
                >
                    <el-table-column type="selection" width="40" align="center"></el-table-column>
                    <!--                            <el-table-column type="selection" width="40" align="center"></el-table-column>-->
                    <el-table-column prop="materialCode" label="物料编码" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="materialName" label="产品名称" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="model" label="型号" align="center"
                                     show-overflow-tooltip></el-table-column>
                    <el-table-column prop="count" label="数量" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-input type="tel" size="small"
                                      v-model.number="scope.row.count" v-if="scope.row.checked == false"
                                      onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                      placeholder="请输入数量" disabled></el-input>
                            <el-input type="tel" size="small"
                                      v-model.number="scope.row.count" v-else
                                      onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                      placeholder="请输入数量"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelEdit">取 消</el-button>
                    <el-button type="primary" @click="submitEdit">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <el-dialog
                title="选择物料信息"
                :visible.sync="materialVisible"
                :close-on-click-modal="false"
                width="50%"
                center>
            <div style="display: flex;margin-bottom: 10px">
                <div style="width: 80px;margin-top: 5px">
                    物料编码：
                </div>
                <div>
                    <el-input v-model="searchMaterialItem.materialCode" size="small"
                              placeholder="请输入物料编码"
                    ></el-input>
                </div>
                <div style="width: 80px;margin-top: 5px">
                    选择部件：
                </div>
                <div>
                    <el-select v-model="searchMaterialItem.component" style="width: 100%"
                               size="small"
                               filterable placeholder="请选择部件名称">
                        <el-option
                                v-for="(item,index) in componentNameList"
                                :key="index"
                                :label="item.componentName"
                                :value="item.component">
                        </el-option>
                    </el-select>
                </div>
                <div style="text-align: center;width: 200px">
                    <el-button type="primary" icon="el-icon-search" size="small"
                               @click="searchBasicBtn">搜索
                    </el-button>
                    <el-button type="primary" icon="el-icon-refresh" size="small"
                               @click="resetBasicBtn">重置
                    </el-button>
                </div>
            </div>
           <div>
               <el-table
                       ref="mainMaterialTable"
                       :data="mainMaterialList"
                       tooltip-effect="dark"
                       style="width: 100%"
                       max-height="400"
                       border
                       :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                       :cell-style="{padding:'0',height: lineHeight}"
                       @selection-change="handleSelectionChangeMaterial"
               >
                   <el-table-column type="selection" width="40" align="center"></el-table-column>
                   <!--                            <el-table-column type="selection" width="40" align="center"></el-table-column>-->
                   <el-table-column prop="materialCode" label="物料编码" align="center"
                                    show-overflow-tooltip></el-table-column>
                   <el-table-column prop="materialName" label="产品名称" align="center"
                                    show-overflow-tooltip></el-table-column>
                   <el-table-column prop="componentName" label="部件" align="center"
                                    show-overflow-tooltip></el-table-column>
                   <el-table-column prop="model" label="型号" align="center"
                                    show-overflow-tooltip></el-table-column>
                   <el-table-column prop="count" label="数量" align="center" show-overflow-tooltip>
                       <template slot-scope="scope">
                           <el-input type="tel" size="small"
                                     v-model.number="scope.row.count" v-if="scope.row.checked == false"
                                     onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                     placeholder="请输入数量" disabled></el-input>
                           <el-input type="tel" size="small"
                                     onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
                                     v-model.number="scope.row.count" v-else
                                     placeholder="请输入数量"></el-input>
                       </template>
                   </el-table-column>
               </el-table>
           </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="mainCancel">取 消</el-button>
                <el-button type="primary" @click="mainSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageList: [15, 20, 30, 50],
                isLoad: false,
                materialVisible: false,
                tableHeight: '',
                tableData: [],
                tableList: [],
                mainMaterialList: [],
                componentNameList: [],
                materialList: [],
                packForm: {
                    id: '',
                    listName: '',
                    deviceType: '',
                    remark: '',
                    packingListDetailStr: ''
                },
                packDetail: {
                    listName: '',
                    deviceType: '',
                    remark: '',
                },
                table: {},
                packRules: {
                    listName: [{required: true, message: '请输入物料清单名称', trigger: 'blur'}],
                    deviceType: [{required: true, message: '请输入使用机型', trigger: 'blur'}],
                },
                deviceTypes: [
                    {
                        value: 0,
                        label: '塔机',
                    },
                    {
                        value: 1,
                        label: '升降机',
                    },
                ],
                componentList: [
                    {wholeCode: 'basic', name: '基础物料'},
                    {wholeCode: 'basic1', name: '基础物料1'},
                ],
                option: {
                    pageSize: 15,
                    pageIndex: 1,
                },
                searchItem: {
                    pageSize: 15,
                    pageIndex: 1,
                },
                searchMaterialItem: {
                    materialCode: '',
                    component: '',
                },
                totalPage: 10,
                editDialogVisible: false,
                infoDialogVisible: false,
                dialogTableVisible: false,
                lineHeight: '',
                editTitle: '',
                component: '',
                selectLists: '',
                selectList: '',
                type: 0,
                showTitle: '',
                mainMaterialListStr: '',
                multipleSelection: [],
            };
        },
        methods: {
            pageChange(option) {
                this.option.pageIndex = option;
                this.searchItem.pageIndex = option;
                this.$api.pagePackingList(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.pagePackingList(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            reset() {
                this.pageChange(1);
            },
            searchBtn() {
                this.pageChange(1);
            },
            addObject() {
                this.editDialogVisible = true;
                this.editTitle = '新增物料管理清单'
                this.type = 0;
                this.isLoad = false;
                this.table = {}
                this.packForm = {
                    id: null,
                    listName: '',
                    deviceType: '',
                    remark: '',
                    packingListDetailStr: ''
                }
                this.$api.getDictionaryData().then(res => {
                    if (res.code == 200) {
                        this.componentList = res.data.componentList;
                    }
                });
                this.$api.getMaterialList({warehouseType: 0}).then(res => {
                    if (res.code == 200) {
                        this.materialList = res.data;
                        this.selectLists = JSON.stringify(this.materialList)
                    }
                })
            },
            cancel(formName) {
                this.$refs[formName].resetFields();
                this.editDialogVisible = false;
            },
            submit(formName) {
                this.isLoad = true;
                var mainOutDetailStr = '';
                for (var i in this.table) {
                    for (var item of this.table[i]) {
                        if (item.count == undefined || item.count == 0 || item.count == null) {
                            this.$message.error('物料清单中的物料不能为空或者是0');
                            this.isLoad = false;
                            return;
                        }
                        var flag = this.getRepeatItem(this.table[i]);
                        if (flag) {
                            this.$message.error('物料清单中的物料存在重复录入情况，请仔细查阅');
                            this.isLoad = false;
                            return;
                        }
                        if (mainOutDetailStr == '') {
                            mainOutDetailStr = item.id + ',' + item.count
                        } else {
                            mainOutDetailStr = mainOutDetailStr + '|' + item.id + ',' + item.count
                        }
                    }
                }
                this.packForm.packingListDetailStr = mainOutDetailStr;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.type == 0) {
                            this.$api.addPackingList(this.packForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange(1);
                                    this.$message.success(res.message);
                                    this.editDialogVisible = false;
                                    this.isLoad = false;
                                    this.$refs[formName].resetFields();
                                } else {
                                    this.$message.error(res.message);
                                    this.isLoad = false;
                                }
                            })
                        } else {
                            this.$api.updatePackingList(this.packForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange(1);
                                    this.$message.success(res.message);
                                    this.editDialogVisible = false;
                                    this.isLoad = false;
                                    this.$refs[formName].resetFields();
                                } else {
                                    this.$message.error(res.message);
                                    this.isLoad = false;
                                }
                            })
                        }
                    } else {
                        this.isLoad = false;
                        return false;
                    }
                });
            },
            editPacking(id) {
                this.type = 1;
                this.isLoad = false;
                this.$api.getDictionaryData().then(res => {
                    if (res.code == 200) {
                        this.componentList = res.data.componentList;
                    }
                });
                this.$api.getMaterialList({warehouseType: 0}).then(res => {
                    if (res.code == 200) {
                        this.materialList = res.data;
                        this.selectLists = JSON.stringify(this.materialList);
                    }
                })
                this.$api.getPackingListDetail({id: id}).then(res => {
                    if (res.code == 200) {
                        this.editDialogVisible = true;
                        this.packForm = {
                            id: res.data.id,
                            listName: res.data.listName,
                            deviceType: res.data.deviceType,
                            remark: res.data.remark,
                        }
                        this.editTitle = '修改《' + res.data.listName + '》物料清单'
                        this.table = res.data.materialMap;
                    } else {
                        this.$message.error(res.message);
                    }
                })
            },
            showPacking(id) {
                this.$api.getPackingListDetail({id: id}).then(res => {
                    if (res.code == 200) {
                        this.infoDialogVisible = true;
                        this.packDetail = {
                            listName: res.data.listName,
                            deviceType: res.data.deviceType,
                            remark: res.data.remark,
                        }
                        this.showTitle = res.data.listName + '详情'
                        this.table = res.data.materialMap;
                    } else {
                        this.$message.error(res.message);
                    }
                })
            },
            deletePacking(id) {
                this.$confirm('你确定要删除该物料清单信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.deletePackingList({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange(1);
                            this.$message.success(res.message);
                        }
                    })
                }).catch(() => {
                })
            },
            handleSelectionChange(val) {
                var flag = false;
                if (this.multipleSelection.length < val.length) {
                    flag = true;
                }
                if (flag) {
                    for (var item of this.tableList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    for (var item of this.tableList) {
                        item.checked = false;
                    }
                    for (var item of this.tableList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                }
                this.multipleSelection = val
                this.$forceUpdate();
            },
            getComponent(val) {
                this.tableList = [];
                for (var item of this.materialList) {
                    if (item.component == val) {
                        item.checked = false;
                        this.tableList.push(item)
                    }
                }
                this.materialList = JSON.parse(this.selectLists);
            },
            chooseModel(index, value, val) {
                //var newArr = value;
                var temp = JSON.parse(this.selectLists);
                for (var item of temp) {
                    if (val == item.model) {
                        value.splice(index, 1, item)
                        this.$forceUpdate();
                    }
                }
                this.materialList = JSON.parse(this.selectLists);
            },
            chooseCode(index, value, val) {
                var temp = JSON.parse(this.selectLists);
                for (var item of temp) {
                    if (val == item.materialCode) {
                        value.splice(index, 1, item)
                        this.$forceUpdate();
                    }
                }
                this.materialList = JSON.parse(this.selectLists);
            },
            deleteDetails(index, value) {
                value.splice(index, 1)
            },
            addDetails(key, value) {
                value.push({})
            },
            addRow() {
                this.dialogTableVisible = true;
                this.tableList = [];
                this.component = '';
                this.toBottom()
            },
            cancelEdit() {
                this.dialogTableVisible = false;
                this.component = '';
                this.tableList = [];
            },
            submitEdit() {
                var newArr = [];
                var newObj = {};
                var name = '';
                if (this.tableList.length < 1) {
                    this.$message.error('所选新增的部件物料不能为空')
                    return;
                }
                for (var item of this.tableList) {
                    if (item.checked == true) {
                        if (item.count < 1 || item.count == '') {
                            this.$message.error('所选新增的部件物料不能为空或者0')
                            return;
                        }
                        newArr.push(item);
                    }
                }
                for (var item of this.componentList) {
                    if (item.wholeCode == this.component) {
                        name = item.name;
                    }
                }
                newObj[name] = newArr;
                Object.assign(this.table, newObj)
                this.dialogTableVisible = false;
                this.toBottom()
            },
            deleteDetail(key) {
                delete this.table[key];
                this.$forceUpdate();
            },
            toBottom() {
                this.$nextTick(() => {
                    let middle = this.$refs["middle"];
                    middle.scrollTop = middle.scrollHeight;
                })
            },
            toMaterialBottom() {
                this.$nextTick(() => {
                    let middle = this.$refs["mainMaterialTable"];
                    middle.scrollTop = middle.scrollHeight;
                })
            },
            getRepeatItem(arr) {
                for (var i = 0; i < arr.length; i++) {//遍历从0开始的数组
                    for (var j = i + 1; j < arr.length; j++) {//遍历从1开始的数组
                        if (arr[i].materialCode === arr[j].materialCode) {//判断前一个值与后一个值全等
                            return true;
                        }
                    }
                }
            },
            addMaterial() {
                this.multipleSelection = []
                this.materialVisible = true;
                var componentNames = [];
                this.$api.getMaterialList({warehouseType: 0}).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data) {
                            item.checked = false;
                        }
                        if (res.code == 200) {
                            for (var item of res.data) {
                                var componentObj = {};
                                componentObj.component = item.component;
                                componentObj.componentName = item.componentName;
                                componentNames.push(componentObj)
                            }
                            var checkedArr = []
                            for (var item of res.data) {
                                if (JSON.stringify(this.table).indexOf(item.materialCode) != -1) {
                                    item.checked = true;
                                    checkedArr.push(item);
                                }
                            }
                            for (var item of checkedArr) {
                                for (var itemElement of this.table[item.componentName]) {
                                    if (item.materialCode == itemElement.materialCode){
                                        item.count = itemElement.count;
                                    }
                                }
                            }
                            var newArr = []
                            for (var item of checkedArr) {
                                newArr.push(item)
                            }
                            for (var item of res.data) {
                                newArr.push(item);
                            }
                            this.mainMaterialList = this.uniqueId(newArr);
                            this.mainMaterialListStr = JSON.stringify(this.uniqueId(newArr));
                            this.componentNameList = this.uniqueCom(componentNames);
                            this.toggleSelection(checkedArr)
                        }
                    }
                })
            },
            handleSelectionChangeMaterial(val) {
                var flag = false;
                if (this.multipleSelection.length < val.length) {
                    flag = true;
                }
                if (flag) {
                    for (var item of this.mainMaterialList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    for (var item of this.mainMaterialList) {
                        item.checked = false;
                    }
                    for (var item of this.mainMaterialList) {
                        if (val.length > 0) {
                            for (const itemObj of val) {
                                if (item.id == itemObj.id) {
                                    if (item.checked) {
                                        continue;
                                    } else {
                                        item.checked = true;
                                    }
                                }
                            }
                        }
                    }
                }
                this.multipleSelection = val
            },
            searchBasicBtn() {
                var newArr = [];
                var checkList = [];
                var materials = JSON.parse(this.mainMaterialListStr)
                for (const item of this.mainMaterialList) {
                    if (item.checked){
                        checkList.push(item)
                    }
                }
                for (var item of materials) {
                    checkList.push(item)
                }
                this.mainMaterialListStr = JSON.stringify(this.uniqueId(checkList));
                for (var item of this.uniqueId(checkList)) {
                    if (this.searchMaterialItem.materialCode != '' && this.searchMaterialItem.materialCode != undefined
                        && this.searchMaterialItem.component != '' && this.searchMaterialItem.component != undefined) {
                        if (item.component.indexOf(this.searchMaterialItem.component) > -1) {
                            if (item.materialCode.indexOf(this.searchMaterialItem.materialCode) > -1) {
                                newArr.push(item)
                            }
                        }
                    } else if (this.searchMaterialItem.materialCode != '' && this.searchMaterialItem.materialCode != undefined) {
                        if (item.materialCode.indexOf(this.searchMaterialItem.materialCode) > -1) {
                            newArr.push(item)
                        }
                    } else if (this.searchMaterialItem.component != '' && this.searchMaterialItem.component != undefined) {
                        if (item.component.indexOf(this.searchMaterialItem.component) > -1) {
                            newArr.push(item)
                        }
                    } else {
                        newArr.push(item)
                    }
                }
                this.mainMaterialList = this.uniqueId(newArr);
                var checkedArr = []
                for (const item of this.uniqueId(newArr)) {
                    if (item.checked) {
                        checkedArr.push(item)
                    }
                }
                this.toggleSelection(checkedArr);
                this.toMaterialBottom();
            },
            resetBasicBtn() {
                var allArr = JSON.parse(this.mainMaterialListStr);
                this.searchMaterialItem = {
                    materialCode: '',
                    component: '',
                }
                var newArr = [];
                for (var item of this.mainMaterialList) {
                    if (item.checked){
                        newArr.push(item)
                    }
                }
                for (var item of allArr) {
                    if (item.checked){
                        newArr.push(item)
                    }
                }
                for (const allArrElement of allArr) {
                    newArr.push(allArrElement)
                }
                this.mainMaterialList = this.uniqueId(newArr);
                var checkedArr = []
                for (const item of this.uniqueId(this.mainMaterialList)) {
                    if (item.checked) {
                        checkedArr.push(item)
                    }
                }
                this.toggleSelection(checkedArr)
            },
            uniqueId(arr) {
                var newArr = [];
                var obj = [];
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].id]) {
                        newArr.push(arr[i]);
                        obj[arr[i].id] = true;
                    }
                }
                return newArr;
            },
            toggleSelection(rows) {
                if (rows) {
                    this.$nextTick(function () {
                        for (var row of rows) {
                            this.$refs.mainMaterialTable.toggleRowSelection(row, true)
                        }
                    })
                }
            },
            mainCancel() {
                this.materialVisible = false;
                this.multipleSelection = [];
            },
            mainSubmit() {
                var newArr = [];
                var newObj = {};
                var name = '';
                if (this.mainMaterialList.length < 1) {
                    this.$message.error('所选新增的物料信息不能为空')
                    return;
                }
                for (var item of this.mainMaterialList) {
                    if (item.checked == true) {
                        if (item.count < 1 || item.count == '') {
                            this.$message.error('所选新增的部件物料数量不能为空或者0')
                            return;
                        }
                        newArr.push(item);
                    }
                }
                for (var item of newArr) {
                    if (item.componentName in newObj) {
                        for (const key in newObj) {
                            if (item.componentName == key) {
                                newObj[key].push(item);
                            } else {
                                continue;
                            }
                        }
                    } else {
                        var otherArr = []
                        otherArr.push(item)
                        newObj[item.componentName] = otherArr;
                    }

                }
                this.table = newObj
                this.materialVisible = false;
                this.toBottom()
                this.multipleSelection = [];
            },
            uniqueCom(arr) {
                var newArr = [];
                var obj = [];
                for (var i = 0; i < arr.length; i++) {
                    if (!obj[arr[i].component]) {
                        newArr.push(arr[i]);
                        obj[arr[i].component] = true;
                    }
                }
                return newArr;
            },
        },
        created() {
            this.pageChange(1);
            this.tableHeight = (window.innerHeight) * 0.76
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbianji {
        margin-right: 20px;
        color: #20a0ff;
    }

    .iconshanchu {
        margin-left: 20px;
        color: red;
    }

    .operation {
        display: flex;
        justify-content: center;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 6%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .text-show {
        color: #20a0ff;
        text-align: center;
        font-size: 20px;
        font-family: '微软雅黑';
    }

    .add-tab {
        text-align: right;
        margin-bottom: 10px;
    }
</style>