import { render, staticRenderFns } from "./PackingList.vue?vue&type=template&id=dc9ecf88&scoped=true"
import script from "./PackingList.vue?vue&type=script&lang=js"
export * from "./PackingList.vue?vue&type=script&lang=js"
import style0 from "./PackingList.vue?vue&type=style&index=0&id=dc9ecf88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc9ecf88",
  null
  
)

export default component.exports